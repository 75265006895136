import React, { useState } from "react";
import { useGlobalStore } from "../../../../../utils/global-store/vo/Vo-globalStore";
import { useTranslation } from "react-i18next";

//services
import CartService from "../../../../../services/vo/add-to-cart-service";
import ProductService from "../../../../../services/vo/product-service";
import AuthService from "../../../../../services/vo/auth-service";

import ProductPdfPopup from "../../../shared-components/product-pdf-popup/ProductPdfPopup";
import BluePdf from "../../../../../images/pdf_icon_blue.svg";
import RedPdf from "../../../../../images/pdf_icon_green.svg";
import defaultIcon from "../../../../../images/icon_no_image";
import Loading from "../../../shared-components/loading/Loading";

//utils
import PriceFormatter from "../../../../../utils/price-utils/PriceFormatter";
import OfferDiscounts from "../../../../../utils/price-utils/offerDiscounts";

function ProductDetailsComponent(props) {
  const [globalVariable] = useGlobalStore(true);
  const {
    onCloseClick,
    productInfo,
    onProductRemove,
    OnSelectClick,
    selectedMBI,
  } = props;
  const [pdfPopupVisible, setPdfPopupVisible] = useState(false);
  const [openpdfUrl, setPdfUrl] = useState(null);
  const [loading, SetLoading] = useState(false);

  const imageUrl = productInfo.offerBgImgURL;

  const pdfNewTabStyle =
    "body{display:flex;justify-content:center}" +
    ".loader { margin-top:15%;	border: 6px solid #f3f3f3;border-radius: 50%;border-top: 6px solid #03687c; width: 60px;height: 60px;-webkit-animation: spin 2s linear infinite;animation: spin 2s linear infinite;}" +
    "@-webkit-keyframes spin {0% { -webkit-transform: rotate(0deg); }100% { -webkit-transform: rotate(360deg); }}" +
    "@keyframes spin {0% { transform: rotate(0deg);}100% { transform: rotate(360deg);}}";

  const { t } = useTranslation();

  const onOpenTermsAndConditionsPdfClick = () => {
    if (!loading) {
      const newTab = window.open("");
      newTab.document.write(
        "<head><title>Loading..</title><style>" +
          pdfNewTabStyle +
          "</style></head>"
      );
      newTab.document.write('<body><div class="loader"></div></body>');
      LoadTermsPdf(newTab);
    }
  };

  async function LoadTermsPdf(newTab) {
    try {
      SetLoading(true);
      if (AuthService.isAuthorized()) {
        const response = await ProductService.GetTermsPDFURL(productInfo.id);
        if (response.data != null && response.data?.status) {
          newTab.location.replace(response.data?.pdfUrl);
          SetLoading(false);
        }
      } else {
        const response = await ProductService.GetTermsPDFURLByLang(
          productInfo.id,
          globalVariable.moduleData?.language
        );
        if (response.data != null && response.data?.status) {
          newTab.location.replace(response.data?.pdfUrl);
          SetLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      newTab.document.body.innerHTML = "";
      newTab.document.write(
        "<div><h4>Something wrong please try again<h4></div>"
      );
      SetLoading(false);
    }
  }

  const onOpenComponentPdfClick = () => {
    if (!loading) {
      SetLoading(true);
      if (
        !(
          productInfo?.ipidDocumentURL == null ||
          productInfo?.ipidDocumentURL == ""
        )
      ) {
        setPdfUrl(productInfo.ipidDocumentURL);
        window.open(productInfo?.ipidDocumentURL, "_blank");
        SetLoading(false);
      } else {
        const newTab = window.open("");
        newTab.document.write(
          "<head><title>Loading..</title><style>" +
            pdfNewTabStyle +
            "</style></head>"
        );
        newTab.document.write('<body><div class="loader"></div></body>');
        LoadComponentPdf(newTab);
      }
    }
  };

  async function LoadComponentPdf(newTab) {
    try {
      if (AuthService.isAuthorized()) {
        const response = await ProductService.GetComponentsPDFURL(
          productInfo.id
        );
        if (response.data != null && response.data?.status) {
          newTab.location.replace(response.data?.pdfUrl);
        }
        SetLoading(false);
      } else {
        const response = await ProductService.GetComponentsPDFURLByLang(
          productInfo.id,
          globalVariable.moduleData?.language
        );
        if (response.data != null && response.data?.status) {
          newTab.location.replace(response.data?.pdfUrl);
        }
        SetLoading(false);
      }
    } catch (err) {
      console.log(err);
      newTab.document.body.innerHTML = "";
      newTab.document.write(
        "<div><h4>Something wrong please try again<h4></div>"
      );
      SetLoading(false);
    }
  }

  const OnDetailsClick = () => {
    if (!pdfPopupVisible) {
      setPdfPopupVisible(true);
    } else {
      setPdfPopupVisible(false);
    }
  };

  const isProductSelected = (product) => {
    return CartService.CheckItemAdded(product);
  };

  const IsValidForDiscount = (offer) => {
    if (offer?.isSpecial != true && offer?.type === "Extra" && selectedMBI) {
      const discountPercentage = offer.extraReducedPrice?.find(
        (x) => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId
      );
      if (
        discountPercentage?.reducedPercentage &&
        discountPercentage?.reducedPercentage > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const HandleSelect = (offer) => {
    if (IsValidForDiscount(offer)) {
      const newOffer = { ...offer };
      newOffer.yearlyPaymentOriginal = offer.yearlyPayment;
      newOffer.monthlyPaymentOriginal = offer.monthlyPayment;
      newOffer.yearlyPayment = parseFloat(
        OfferDiscounts.getDiscountYearlyPrice(offer, selectedMBI)
      );
      newOffer.monthlyPayment = parseFloat(
        OfferDiscounts.getDiscountMonthlyPrice(offer, selectedMBI)
      );
      newOffer.discountedPrice = true;
      OnSelectClick(newOffer, offer.isSpecial);
    } else {
      offer.discountedPrice = false;
      OnSelectClick(offer, offer.isSpecial);
    }
  };

  const PriceText = ({ yearlyPrice, monthlyPrice }) => {
    return (
      <div class="flex">
        <div class="flex price mr-5">
          <span class="monthly-price m-price-ext">
            {PriceFormatter.getCurrencyFormat(
              globalVariable?.moduleData.language
            ).format(monthlyPrice)}{" "}
            {AuthService.getCurrencyCode(t("language"))}
          </span>
          <span class="month">/ {t("product_month_text")}</span>
        </div>
        <div class="flex price">
          <span class="yearly-price">
            {PriceFormatter.getCurrencyFormat(
              globalVariable?.moduleData.language
            ).format(yearlyPrice)}{" "}
            {AuthService.getCurrencyCode(t("language"))}
          </span>
          <span class="month">/ {t("product_months_text")}</span>
        </div>
      </div>
    );
  };

  return (
    <div id="popup1" class="overlay overlay-ext detail-popup detail-pane">
      <div class="popup popup-ext">
        {loading ? <Loading></Loading> : ""}
        <a class="close" onClick={() => onCloseClick()}>
          &times;
        </a>
        <div class="content content-ext">
          <div class="popup-wrap p-20">
            <div class="popup-body mb-20 pop-body-ext">
              <div class="mbi-title">{productInfo?.pName}</div>
              {productInfo?.yearlyPayment != null &&
              productInfo?.monthlyPayment != null ? (
                IsValidForDiscount(productInfo) ? (
                  <div>
                    <del>
                      <PriceText
                        yearlyPrice={productInfo?.yearlyPayment}
                        monthlyPrice={productInfo?.monthlyPayment}
                      ></PriceText>
                    </del>
                    <div class="new-price">
                      <PriceText
                        yearlyPrice={OfferDiscounts.getDiscountYearlyPrice(
                          productInfo,
                          selectedMBI
                        )}
                        monthlyPrice={OfferDiscounts.getDiscountMonthlyPrice(
                          productInfo,
                          selectedMBI
                        )}
                      ></PriceText>
                    </div>
                  </div>
                ) : (
                  <PriceText
                    yearlyPrice={productInfo?.yearlyPayment}
                    monthlyPrice={productInfo?.monthlyPayment}
                  ></PriceText>
                )
              ) : (
                ""
              )}
              <div class="documents">
                <span></span>
                <span></span>
              </div>
              <p class="mb-20 product-description">
                {productInfo?.pDescription}{" "}
              </p>
              <div className="term-policy-parent">
                <a
                  class="javascript: void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpenComponentPdfClick()}
                >
                  <img src={RedPdf} />{" "}
                  <span> {t("product_details_popup_policy")}</span>
                </a>
                <a
                  class="javascript: void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpenTermsAndConditionsPdfClick()}
                >
                  <img src={BluePdf} class="ml-10" />
                  <span> {t("product_details_popup_conditions")}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pdfPopupVisible && openpdfUrl != null ? (
        <ProductPdfPopup onCloseClick={OnDetailsClick} pdfurl={openpdfUrl} />
      ) : (
        ""
      )}
    </div>
  );
}

export default ProductDetailsComponent;
