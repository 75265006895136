//
import React from "react";
import { render } from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/language-switcher.css";
import "./styles/information.min.css";
import "./styles/layout.min.css";
import "./styles/ac.min.css";
import "./styles/ac-logged-out-style.min.css";
import "./styles/index.css";

import "bootstrap/dist/js/bootstrap.bundle";
import App from "./app.component";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import ScrollToTop from "./utils/route-utils/ScrollTop";

const queryParams = new URLSearchParams(window.location.search);
const langPara = queryParams.get("language");
let lang = langPara != null ? window.atob(langPara) : "sv-SE";

let language = "";

switch (lang) {
  case "sv-SE": {
    language = "se";
    break;
  }
  case "en-gb": {
    language = "en";
    break;
  }
  case "nn-NO": {
    language = "no";
    break;
  }
  case "fi-FI": {
    language = "fi";
    break;
  }
  case "da-DK": {
    language = "dk";
    break;
  }
}
i18n.changeLanguage(language);

render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <Router>
        <ScrollToTop />
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
