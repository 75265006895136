//import { useTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";

const setVendorId = (id) => {
  sessionStorage.setItem("vendorId", id);
  return [];
};

const getTitle = () => {
  let { t } = useTranslation();
  let title = "";
  const location = window.location.pathname;

  let path = location != null ? location : "se";
  switch (path) {
    case "/new-vehicle": {
      title = t("new-vehicle");
      break;
    }
    case "/product": {
      title = t("choose_insurance");
      break;
    }
    case "/information": {
      title = t("vo_information_heading");
      break;
    }
    case "/checkout": {
      title = t("checkoutpage_title");
      break;
    }
    case "/payment-accept": {
      title = t("payment_accepted");
      break;
    }
    case "/payment-cancel": {
      title = t("payment_cancel_header");
      break;
    }
    case "/internationalclaim": {
      title = t("claim_title");
      break;
    }
    case "/claim-success": {
      title = t("claim-success");
      break;
    }
    default: {
      title = t("choose_insurance");
      break;
    }
  }
  return title;
};

const getVendorId = () => {
  return sessionStorage.getItem("vendorId");
};

export default { getTitle, getVendorId, setVendorId };
